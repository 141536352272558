import { useCallback, useEffect, useRef } from 'react';
import { createContextPair } from '~/common/kits/context';
import { useOrderData } from '~/orders/hooks';
export const [useDistributionContext, withDistributionContext] = createContextPair(() => {
    const { iterations } = useOrderData();
    const distribution = useRef(null);
    const setDistribution = useCallback((value) => {
        distribution.current = value;
    }, []);
    useEffect(() => {
        setDistribution(null);
    }, [iterations, setDistribution]);
    return {
        distribution,
        setDistribution,
    };
})('Capacity Distribution');
