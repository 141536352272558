import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { toast, useToaster } from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton } from '~/common/components';
import { cx, ignoreHandled, tw } from '~/common/utils';
import { TESTING } from '~/env';
import styles from './Toast.module.scss';
const ToastContent = ({ subTitle, title, type, link, reversed = false, onClick, id, }) => {
    const { handlers } = useToaster();
    const handleClick = () => {
        onClick && onClick();
        toast.remove(id);
    };
    const handleDelete = (event) => {
        event.preventDefault();
        toast.remove(id);
    };
    return (_jsx(Wrapper, { link: link, children: _jsxs("div", { onMouseMove: () => (TESTING ? handlers.endPause() : undefined), className: cx(tw `
            flex justify-between gap-1
            p-1 w-[300px]
            rounded border border-solid
          `, styles[type]), onClick: ignoreHandled(handleClick), children: [_jsxs("div", { className: cx('flex flex-col gap-[4px]', reversed && 'flex-col-reverse'), children: [_jsx("p", { className: "text-greyscale-600 font-brand-t2r", children: title }), subTitle && _jsx("p", { className: "text-greyscale-500 font-brand-b1", children: subTitle })] }), _jsx(IconButton, { "data-stop-propagation": true, size: "s", className: "w-3 h-3 mt-[-4px] mr-[-4px] cursor-pointer mb-auto text-greyscale-500", icon: faTimes, onClick: handleDelete })] }) }));
};
const makeToast = (type) => ({ duration, ...props }) => {
    toast.custom(({ type: ttype, ...toastProps }) => (_jsx(ToastContent, { ...toastProps, type: type, ...props, title: props.title, id: props.title + props.subTitle })), { duration, id: props.title + props.subTitle });
};
export const Toast = {
    error: makeToast('error'),
    info: makeToast('info'),
    success: makeToast('success'),
    warning: makeToast('warning'),
    default: makeToast('default'),
};
function Wrapper({ link, children }) {
    return link ? (_jsx(RouterLink, { className: "contents", to: link, children: children })) : (_jsx(_Fragment, { children: children }));
}
