import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { useCustomerParams } from '~/customers/Customer';
import { qk } from '~/root/query-keys';
import { Payments, PaymentsInit } from '../domain';
export const usePayments = () => {
    const { subscription_id } = useCustomerParams();
    const { queryParams } = useParsedQuery();
    const params = {
        ...getFlatQuery(queryParams),
        limit: 25,
    };
    return useQuery({
        queryKey: [...qk.subscription(subscription_id), 'payments', 'list', params],
        queryFn: () => httpClient.get(`/v1/staff/subscriptions/${subscription_id}/payments`, {
            decoder: Payments,
            params,
        }),
        keepPreviousData: true,
    });
};
export const usePaymentsData = assertQueryData(usePayments);
export const usePaymentsInit = () => {
    const { subscription_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.subscription(subscription_id), 'payments', 'init'],
        queryFn: () => httpClient.get(`/v1/staff/subscriptions/${subscription_id}/payments/init`, {
            decoder: PaymentsInit,
        }),
    });
};
export const usePaymentsInitData = assertQueryData(usePaymentsInit);
