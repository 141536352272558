import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { Init, SubscriptionsList } from '../domain';
export const SUBSCRIPTIONS_URL = '/subscriptions';
export const useInit = () => {
    return useQuery({
        queryKey: qk.subscriptionsInit,
        queryFn: () => httpClient.get(`/v1/staff/subscriptions/init`, { decoder: Init }),
        staleTime: Infinity,
    });
};
export const useInitData = assertQueryData(useInit);
export function useSubscriptionsList() {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.subscriptionsList, params],
        queryFn: () => httpClient.get('/v1/staff/subscriptions', { decoder: SubscriptionsList, params }),
        keepPreviousData: true,
    });
}
