import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUpdateCache } from '~/common/hooks';
import { useFeatureFlags } from '~/common/kits/features';
import { assertQueryData, httpClient } from '~/common/utils';
import { useCustomerParams } from '~/customers/Customer';
import { Subscription, } from '~/customers/domain';
import { useUpdateCustomersCache } from '~/customers/hooks';
import { qk } from '~/root/query-keys';
import { CapacityBreakdown, } from '../domain';
import { useInitData } from './subscriptions';
export const useSubscription = () => {
    const { subscription_id } = useCustomerParams();
    return useQuery({
        queryKey: qk.subscription(subscription_id),
        queryFn: () => httpClient.get(`/v1/staff/subscriptions/${subscription_id}`, { decoder: Subscription }),
    });
};
export const useSubscriptionData = assertQueryData(useSubscription);
export const useUpdateSubscriptionsCache = () => {
    const { subscription_id } = useCustomerParams();
    return useUpdateCache(qk.subscription(subscription_id), `Subscription ${subscription_id} cache is not defined`);
};
export const useSubscriptionUpdateMutation = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.put(`/v1/staff/subscriptions/${subscription_id}`, { data, decoder: Subscription }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            client.invalidateQueries(qk.subscriptionsList);
            client.invalidateQueries(qk.customer(next.owner_id));
            client.invalidateQueries(qk.organisationsAll);
        },
    });
};
export const useGeneralInfoMutation = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    const updateCustomerCache = useUpdateCustomersCache();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/subscriptions/${subscription_id}/general`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            updateCustomerCache((prev) => ({
                ...prev,
                subscription: { ...prev.subscription, timezone_id: next.timezone_id },
            }));
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useTopupSubscriptionMutation = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/subscriptions/${subscription_id}/topup`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useCapacityBreakdown = () => {
    const { subscription_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.subscription(subscription_id), 'capacity-breakdown'],
        queryFn: () => httpClient
            .get(`/v1/staff/subscriptions/${subscription_id}/capacity-breakdown`, {
            decoder: CapacityBreakdown,
        })
            .catch((error) => (error.response.status === 404 ? null : Promise.reject(error))),
    });
};
export const useCapacityBreakdownData = assertQueryData(useCapacityBreakdown);
export const useSubscriptionCancel = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/subscriptions/${subscription_id}/cancel`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useUpdateUploadWindowMutation = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/subscriptions/${subscription_id}/upload-window`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
        },
    });
};
export const useDeleteUploadWindowMutation = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: () => httpClient.delete(`/v1/staff/subscriptions/${subscription_id}/upload-window`, {
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
        },
    });
};
export const useEditUpcomingEvent = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/subscriptions/${subscription_id}/event`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useDeleteUpcomingEvent = () => {
    const client = useQueryClient();
    const { subscription_id } = useCustomerParams();
    return useMutation({
        mutationFn: (data) => httpClient.delete(`/v1/staff/subscriptions/${subscription_id}/event`, {
            data,
            decoder: Subscription,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.subscription(next.id), next);
            client.invalidateQueries(qk.subscriptionsList);
        },
    });
};
export const useSubscriptionFeatureFlags = () => {
    const queryClient = useQueryClient();
    const { features: targetFeatures, customer_account_id: targetable_id, owner_id, } = useSubscriptionData();
    const { features: initFeatures } = useInitData();
    const updateSubscription = useUpdateSubscriptionsCache();
    return useFeatureFlags({
        targetable_id,
        targetFeatures,
        initFeatures,
        onMutationSuccess: (features) => {
            updateSubscription((data) => ({
                ...data,
                features,
            }));
            queryClient.invalidateQueries(qk.customer(owner_id));
        },
    });
};
