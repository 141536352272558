import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react';
import { forwardRef } from 'react';
import { useTabIndex } from '~/common/hooks';
const FloatingTreeHoc = ({ children }) => {
    const parentId = useFloatingParentNodeId();
    // This is a root, so we wrap it with the tree
    if (parentId === null) {
        return _jsx(FloatingTree, { children: children });
    }
    return _jsx(_Fragment, { children: children });
};
export function withFloatingTree(Component) {
    return (props) => {
        return _jsx(FloatingTreeHoc, { children: Component && _jsx(Component, { ...props }) });
    };
}
export const FloatingDiv = forwardRef((props, ref) => {
    const tabIndex = useTabIndex();
    return _jsx("div", { ...props, ref: ref, tabIndex: tabIndex });
});
// TODO extend this once we know what other extensions our users want to use
export const outsidePress = (event) => {
    const target = event.target;
    return !target.shadowRoot && !target.closest('.hot-toast');
};
