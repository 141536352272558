import { useQuery } from '@tanstack/react-query';
import { assertQueryData, httpClient } from '~/common/utils';
import { Init } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useInit() {
    return useQuery({
        queryKey: qk.ordersInit,
        queryFn: () => httpClient.get('/v1/staff/orders/init', { decoder: Init }),
        staleTime: Infinity,
    });
}
export const useInitData = assertQueryData(useInit);
