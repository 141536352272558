import { useHistory, useLocation } from 'react-router-dom';
import { createContextPair } from '~/common/kits/context';
import { nonNullable } from '~/common/utils';
import { useCustomerParams } from '~/customers/Customer';
import { SUBSCRIPTIONS_URL } from '../hooks';
export const tabs = [
    { key: 'general', label: 'Subscription' },
    { key: 'payments', label: 'Payments' },
    { key: 'activity', label: 'Activity' },
];
const getActiveTab = (pathname) => {
    const lastElement = nonNullable(pathname.split('/').pop());
    return isNaN(+lastElement) ? lastElement : 'general';
};
export const [useSubscriptionsContext, withSubscriptionsContext] = createContextPair(() => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { subscription_id, customer_id } = useCustomerParams();
    return {
        activeTab: getActiveTab(pathname),
        setActiveTab: (tab) => {
            history.push({
                pathname: `${SUBSCRIPTIONS_URL}/${subscription_id}/customer/${customer_id}` +
                    (tab === 'general' ? '' : `/${tab}`),
                search: '',
            });
        },
    };
})('Subscriptions');
