import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useIdParam } from '~/common/hooks';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { Init, Team, TeamsList } from './domain';
export const TEAMS_URL = '/teams';
export const useInit = () => {
    return useQuery({
        queryKey: qk.teamsInit,
        queryFn: () => httpClient.get(`/v1/staff/teams/init`, { decoder: Init }),
    });
};
export const useInitData = assertQueryData(useInit);
export const useTeamsList = () => {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.teamsList, params],
        queryFn: () => httpClient.get('/v1/staff/teams', { decoder: TeamsList, params }),
        keepPreviousData: true,
    });
};
export const useTeamCreation = () => {
    const client = useQueryClient();
    const history = useHistory();
    return useMutation({
        mutationFn: (data) => httpClient.post('/v1/staff/teams', { data, decoder: Team }),
        onSuccess: (next) => {
            const id = next.id;
            client.setQueryData(qk.team(id), next);
            client.invalidateQueries(qk.teamsList);
            history.push(`${TEAMS_URL}/${id}`);
        },
    });
};
export const useTeam = () => {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.team(id),
        queryFn: () => httpClient.get(`/v1/staff/teams/${id}`, { decoder: Team }),
    });
};
export const useTeamData = assertQueryData(useTeam);
export const useTeamMutation = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/teams/${id}`, { data, decoder: Team }),
        onSuccess: (next) => {
            client.setQueryData(qk.team(id), (prev) => ({ ...prev, ...next }));
            client.invalidateQueries(qk.teamsList);
        },
    });
};
export const useTeamDeletion = () => {
    const client = useQueryClient();
    const id = useIdParam();
    return useMutation({
        mutationFn: () => httpClient.delete(`/v1/staff/teams/${id}`),
        onSuccess: () => {
            client.removeQueries(qk.team(id));
            client.invalidateQueries(qk.teamsList);
        },
    });
};
