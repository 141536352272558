import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { useIdParam, useUpdateCache } from '~/common/hooks';
import { useFeatureFlags } from '~/common/kits/features';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { httpClient, assertQueryData } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { Organization, Stats, Init, OrganizationList } from '../domain';
export const ORGANIZATIONS_URL = '/organisations';
export const useInit = () => {
    return useQuery({
        queryKey: qk.organisationsInit,
        queryFn: () => httpClient.get(`/v1/staff/organisations/init`, { decoder: Init }),
    });
};
export const useInitData = assertQueryData(useInit);
export const useOrganizationsList = () => {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.organisationsList, params],
        queryFn: () => httpClient.get('/v1/staff/organisations', { decoder: OrganizationList, params }),
        keepPreviousData: true,
    });
};
export const useCreateOrganization = () => {
    const client = useQueryClient();
    const history = useHistory();
    return useMutation({
        mutationFn: (data) => httpClient.post('/v1/staff/organisations', { data, decoder: Organization }),
        onSuccess: (next) => {
            const id = next.id;
            client.setQueryData(qk.organisation(id), next);
            client.invalidateQueries(qk.organisationsList);
            client.invalidateQueries(qk.ordersInit);
            history.push(`${ORGANIZATIONS_URL}/${id}`);
        },
    });
};
export const useOrganization = () => {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.organisation(id),
        queryFn: () => httpClient.get(`/v1/staff/organisations/${id}`, { decoder: Organization }),
    });
};
export const useOrganizationData = assertQueryData(useOrganization);
export const useUpdateOrganizationCache = () => {
    const id = useIdParam();
    return useUpdateCache(qk.organisation(id), `Organization ${id} cache is not defined`);
};
export const useEditOrganizationInfo = () => {
    const id = useIdParam();
    const client = useQueryClient();
    return useMutation({
        mutationFn: (data) => httpClient.patch(`/v1/staff/organisations/${id}`, { data, decoder: Organization }),
        onSuccess: (next) => {
            client.setQueryData(qk.organisation(id), next);
            client.invalidateQueries(qk.organisationsList);
            client.invalidateQueries(qk.customersAll);
            client.invalidateQueries(qk.ordersInit);
            client.invalidateQueries(qk.organisationActivity(id));
        },
    });
};
export const useEditCustomPortalAndDomainsInfo = () => {
    const id = useIdParam();
    const client = useQueryClient();
    return useMutation({
        mutationFn: (data) => httpClient.put(`/v1/staff/organisations/${id}/portal-info`, {
            data,
            decoder: Organization,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.organisation(id), next);
            client.invalidateQueries(qk.organisationsList);
            client.invalidateQueries(qk.customersAll);
            client.invalidateQueries(qk.organisationActivity(id));
        },
    });
};
export const useStats = () => {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.organisationStats(id),
        queryFn: () => httpClient.get(`/v1/staff/organisations/${id}/stats`, { decoder: Stats }),
    });
};
export const useStatsData = assertQueryData(useStats);
export const useAddCustomersToOrganization = () => {
    const id = useIdParam();
    const client = useQueryClient();
    return useMutation({
        mutationFn: (data) => httpClient.post(`/v1/staff/organisations/${id}/customers`, {
            data,
            decoder: Organization,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.organisation(id), next);
            client.invalidateQueries(qk.customersAll);
            client.invalidateQueries(qk.organisationActivity(id));
            client.invalidateQueries(qk.organisationsList);
        },
    });
};
export const useDeleteCustomersFromOrganization = () => {
    const id = useIdParam();
    const client = useQueryClient();
    return useMutation({
        mutationFn: (data) => httpClient.delete(`/v1/staff/organisations/${id}/customers`, {
            data,
            decoder: Organization,
        }),
        onSuccess: (next) => {
            client.setQueryData(qk.organisation(id), next);
            client.invalidateQueries(qk.customersAll);
            client.invalidateQueries(qk.organisationActivity(id));
            client.invalidateQueries(qk.organisationsList);
        },
    });
};
export const useOrganizationFeatureFlags = () => {
    const { features: targetFeatures, id: targetable_id } = useOrganizationData();
    const { features: initFeatures } = useInitData();
    const updateOrganization = useUpdateOrganizationCache();
    return useFeatureFlags({
        targetable_id,
        targetFeatures,
        initFeatures,
        onMutationSuccess: (features) => updateOrganization((data) => ({
            ...data,
            features,
        })),
    });
};
