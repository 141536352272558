import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient } from '~/common/utils';
import { OrdersBoard, OrdersList } from '~/orders/domain';
import { qk } from '~/root/query-keys';
export function useOrders() {
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        queryKey: [...qk.ordersList, params],
        queryFn: () => httpClient.get('/v1/staff/orders', { decoder: OrdersList, params }),
        keepPreviousData: true,
    });
}
export function useOrdersForBoard() {
    return useQuery({
        queryKey: qk.ordersBoard,
        queryFn: () => httpClient.get('/v1/staff/orders/board', { decoder: OrdersBoard }),
        keepPreviousData: true,
        // since we're going to update it in-place with websockets it makes sense
        // to not refetch it once in 3 mins or whatever that default is
        staleTime: Infinity,
    });
}
export const useOrdersForBoardData = assertQueryData(useOrdersForBoard);
