import { addDays, clamp, min, startOfWeek } from 'date-fns';
import { useLayoutEffect, useState } from 'react';
import { z } from 'zod';
import { createQueryState, useStateSliceSetter } from '~/common/hooks';
import { createContextPair } from '~/common/kits/context';
import { DateWithFallback, PeriodWithFallback, formatYearMonthDay, moveDate } from '~/common/utils';
import { useVisited } from '~/root';
import { useInit } from './hooks';
const useRmsQuery = createQueryState(z.object({
    date: DateWithFallback,
    period: PeriodWithFallback,
    team: z.coerce.number().int().positive().nullable().catch(null),
}), ({ date, team, ...rest }) => ({
    ...rest,
    team: team === null ? 'all' : String(team),
    date: formatYearMonthDay(date),
}));
const useContextData = () => {
    const [loaded, setLoaded] = useState(false);
    const [params, setParams] = useRmsQuery();
    const { data: init } = useInit(params.team);
    const getClampedEndDate = (date) => {
        const startDatePlusPeriod = moveDate(date, params.period, true);
        return init ? min([startDatePlusPeriod, init.max_date]) : startDatePlusPeriod;
    };
    const endDate = getClampedEndDate(params.date);
    const breakdownStart = params.team ? params.date : startOfWeek(params.date, { weekStartsOn: 1 });
    const breakdownEnd = getClampedEndDate(breakdownStart);
    const setDate = (date) => {
        const clampedDate = init ? clamp(date, { start: init.min_date, end: init.max_date }) : date;
        setParams((params) => ({ ...params, date: clampedDate }));
    };
    const setDateByIndex = (index) => {
        setDate(addDays(breakdownStart, index));
    };
    const setTeamId = useStateSliceSetter(setParams, 'team');
    const setPeriod = useStateSliceSetter(setParams, 'period');
    useVisited('rms');
    // we need to constrain current date to the possible range once we download it
    useLayoutEffect(() => {
        if (init && !loaded) {
            setDate(params.date);
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init]);
    return {
        ...params,
        loaded,
        teamId: params.team,
        allTeams: params.team === null,
        endDate,
        breakdownStart,
        breakdownEnd,
        setPeriod,
        setDate,
        setDateByIndex,
        setTeamId,
    };
};
export const [useRmsContext, withRmsContext] = createContextPair(useContextData)('RMS');
