import { useQuery } from '@tanstack/react-query';
import { getFlatQuery, useParsedQuery } from '~/common/kits/table';
import { assertQueryData, httpClient, nonNullable } from '~/common/utils';
import { TransactionsInit, TransactionsList } from '~/customers/domain';
import { qk } from '~/root/query-keys';
import { useCustomerParams } from '../Customer';
export const useTransactionsInit = () => {
    const { customer_id } = useCustomerParams();
    return useQuery({
        queryKey: [...qk.customer(customer_id), 'transactions', 'init'],
        queryFn: () => {
            return httpClient.get(`/v1/staff/customers/${customer_id}/transactions/init`, {
                decoder: TransactionsInit,
            });
        },
        keepPreviousData: true,
    });
};
export const useTransactionsInitData = assertQueryData(useTransactionsInit);
export const useTransactionsList = () => {
    const { customer_id } = useCustomerParams();
    const init = useTransactionsInit();
    const { queryParams } = useParsedQuery();
    const params = getFlatQuery(queryParams);
    return useQuery({
        enabled: !!init.data,
        queryKey: [...qk.customer(customer_id), 'transactions', 'list', params],
        queryFn: () => {
            var _a;
            return httpClient.get(`/v1/staff/customers/${customer_id}/transactions`, {
                decoder: TransactionsList(nonNullable((_a = init.data) === null || _a === void 0 ? void 0 : _a.statuses)),
                params,
            });
        },
        keepPreviousData: true,
    });
};
export const useTransactionsListData = assertQueryData(useTransactionsList);
