import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { arrow, autoUpdate, flip, FloatingArrow, FloatingPortal, offset, safePolygon, shift, useDelayGroup, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole, useTransitionStatus, } from '@floating-ui/react';
import { cloneElement, useRef, useState } from 'react';
import { cx } from '~/common/utils';
import fade from '~/styles/fade.module.scss';
import styles from './Tooltip.module.scss';
export const Tooltip = ({ content, children, color = 'white', className, placement = 'top', delay = 300, compensateOffset = 0, noArrow = false, clickable = false, reference, }) => {
    const [open, setOpen] = useState(false);
    const arrowRef = useRef(null);
    const { context, refs, floatingStyles } = useFloating({
        placement,
        open: open && !!content,
        elements: {
            reference,
        },
        onOpenChange: setOpen,
        middleware: [
            offset(12 - compensateOffset),
            flip(),
            shift({ padding: 8 }),
            arrow({ element: arrowRef }),
        ],
        whileElementsMounted: autoUpdate,
    });
    const { delay: groupDelay, isInstantPhase } = useDelayGroup(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            // delayGroup
            delay: groupDelay === 0 ? { open: delay, close: 150 } : groupDelay,
            restMs: 40,
            handleClose: clickable ? safePolygon() : undefined,
        }),
        useFocus(context),
        useRole(context, { role: 'tooltip' }),
        useDismiss(context),
    ]);
    const { isMounted, status } = useTransitionStatus(context);
    return (
    // TODO use render-props instead of cloneElement to be able to merge
    // multiple ref callbacks from above, make multiRef(ref1, ref2) utility
    // use-case - dropdown popover & tooltip on select fields on the same node
    _jsxs(_Fragment, { children: [reference === undefined &&
                cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props })), isMounted && (_jsx(FloatingPortal, { children: _jsxs("div", { ...getFloatingProps({
                        ref: refs.setFloating,
                        className: cx(styles.tooltip, styles[color], className, fade.floating, clickable && 'pointer-events-auto'),
                        style: floatingStyles,
                        onClick: () => setOpen(false),
                    }), "data-status": status, "data-instant": isInstantPhase, children: [!noArrow && (_jsx(FloatingArrow, { context: context, ref: arrowRef, width: 12, height: 6, className: cx(styles.arrow, styles[color]), tipRadius: 2, strokeWidth: 1 })), content] }) }))] }));
};
