import { useQuery } from '@tanstack/react-query';
import { useIdParam } from '~/common/hooks';
import { ActivityWithoutMeta } from '~/common/kits/activity';
import { assertQueryData, httpClient } from '~/common/utils';
import { qk } from '~/root/query-keys';
const getOrderActivityEndpoint = (id) => `/v1/staff/orders/${id}/activity`;
export function useOrderActivity() {
    const id = useIdParam();
    return useQuery({
        queryKey: qk.orderActivity(id),
        queryFn: () => httpClient.get(getOrderActivityEndpoint(id), { decoder: ActivityWithoutMeta }),
    });
}
export const useOrderActivityData = assertQueryData(useOrderActivity);
