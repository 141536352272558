import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
/**
 * Creates context provider & hook to access it correctly
 */
export const createContextPair = (contextDataHook, children) => (name) => {
    const Context = createContext(null);
    const Provider = ({ children }) => {
        const value = contextDataHook();
        return _jsx(Context.Provider, { value: value, children: children });
    };
    const useEnsuredContext = () => {
        const data = useContext(Context);
        if (!data) {
            throw new Error("There's no data for " + name + ' context. make sure you wrapped parent with withContext');
        }
        return data;
    };
    const withContext = (Component) => {
        return (props) => (_jsxs(Provider, { children: [_jsx(Component, { ...props }), children] }));
    };
    return [useEnsuredContext, withContext];
};
export const combineWrappers = (...wrappers) => {
    return (component) => {
        for (const wrapper of wrappers) {
            component = wrapper(component);
        }
        return component;
    };
};
