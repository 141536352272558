import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';
import { assertQueryData, getLocalStorage, httpClient, setLocalStorage } from '~/common/utils';
import { qk } from '~/root/query-keys';
import { Country } from './domain';
const Countries = z.array(Country);
const COUNTRIES_STORAGE_KEY = 'countries';
export const useCountries = () => useQuery({
    queryKey: qk.countries,
    queryFn: async () => {
        const localStorageCountries = getLocalStorage(COUNTRIES_STORAGE_KEY, null);
        return (localStorageCountries ||
            httpClient
                .get(`/v1/staff/init/countries`, {
                decoder: Countries,
            })
                .then((countries) => {
                setLocalStorage(COUNTRIES_STORAGE_KEY, countries);
                return countries;
            }));
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
});
export const useCountriesData = assertQueryData(useCountries);
export const useDialCodesData = () => {
    const data = useCountriesData();
    return useMemo(() => {
        return data.map(({ value, dial_code, name, country_code }) => ({
            value,
            name: `+${dial_code}`,
            country_name: name,
            country_code,
        }));
    }, [data]);
};
