import { useHistory, useLocation } from 'react-router-dom';
import { useIdParam } from '~/common/hooks';
import { createContextPair } from '~/common/kits/context';
import { nonNullable } from '~/common/utils';
import { ORGANIZATIONS_URL } from '../hooks';
export const tabs = [
    { key: 'general', label: 'General' },
    { key: 'activity', label: 'Activity' },
];
const getActiveTab = (pathname) => {
    const lastElement = nonNullable(pathname.split('/').pop());
    return isNaN(+lastElement) ? lastElement : 'general';
};
export const [useOrganizationsContext, withOrganizationsContext] = createContextPair(() => {
    const { pathname } = useLocation();
    const history = useHistory();
    const id = useIdParam();
    return {
        activeTab: getActiveTab(pathname),
        setActiveTab: (tab) => {
            history.push({
                pathname: `${ORGANIZATIONS_URL}/${id}` + (tab === 'general' ? '' : `/${tab}`),
                search: '',
            });
        },
    };
})('Organizations');
