import { jsx as _jsx } from "react/jsx-runtime";
import { faCalendarLines } from '@fortawesome/pro-regular-svg-icons';
import { withShiftsContext } from './context';
import { SHIFTS_URL } from './hooks';
import { Shifts } from './Shifts';
const ShiftsEntry = withShiftsContext(() => _jsx(Shifts, {}));
const nav = {
    icon: faCalendarLines,
    label: 'Shifts',
    path: SHIFTS_URL,
};
export const shiftsModule = {
    canShow: (permissions) => permissions.shiftsView,
    route: {
        path: SHIFTS_URL,
        component: ShiftsEntry,
    },
    nav,
};
